"use client";

import React, { Fragment } from "react";

import ExpiredSession from "../../organisms/authentication/ExpiredSession"; // Import from exact file to avoid dependency cycle

export default function WithSessionExpiring({
	children,
}: {
	children: React.ReactNode;
}) {
	return (
		<Fragment>
			<ExpiredSession />
			{children}
		</Fragment>
	);
}
