/* eslint-disable */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckAccountLockingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckAccountLockingQuery = { __typename: 'Query', account?: { __typename: 'Account', isAccountLocked?: boolean | null } | null };


export const CheckAccountLockingDocument = gql`
    query CheckAccountLocking {
  account {
    isAccountLocked
  }
}
    `;

/**
 * __useCheckAccountLockingQuery__
 *
 * To run a query within a React component, call `useCheckAccountLockingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAccountLockingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAccountLockingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAccountLockingQuery(baseOptions?: Apollo.QueryHookOptions<CheckAccountLockingQuery, CheckAccountLockingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAccountLockingQuery, CheckAccountLockingQueryVariables>(CheckAccountLockingDocument, options);
      }
export function useCheckAccountLockingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAccountLockingQuery, CheckAccountLockingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAccountLockingQuery, CheckAccountLockingQueryVariables>(CheckAccountLockingDocument, options);
        }
export type CheckAccountLockingQueryHookResult = ReturnType<typeof useCheckAccountLockingQuery>;
export type CheckAccountLockingLazyQueryHookResult = ReturnType<typeof useCheckAccountLockingLazyQuery>;
export type CheckAccountLockingQueryResult = Apollo.QueryResult<CheckAccountLockingQuery, CheckAccountLockingQueryVariables>;