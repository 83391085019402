"use client";

import React from "react";
import { gql } from "@apollo/client";
import { useRouter } from "next/navigation";
import { useCheckAccountLockingQuery } from "./WithAccountLocking.generated";

export default function WithAccountLocking({
	children,
}: {
	children: React.ReactNode;
}) {
	const { data, loading } = useCheckAccountLockingQuery();
	const router = useRouter();

	if (!loading && data?.account?.isAccountLocked) {
		router.replace("/account-locked");
		return null;
	}

	return <>{children}</>;
}

gql`
	query CheckAccountLocking {
		account {
			isAccountLocked
		}
	}
`;
