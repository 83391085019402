"use client";

import React, { useEffect, useState } from "react";
import { usePathname, useSearchParams, useRouter } from "next/navigation";
import { useSession } from "contexts/session";

type Props = {
	children: React.ReactNode;
};

function WithAuthentication({ children }: Props) {
	const [isMounted, setIsMounted] = useState(false);

	const { hasAuthenticatedSession } = useSession();
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const queryString = searchParams.toString();

	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!isMounted) {
		return null;
	}

	if (hasAuthenticatedSession) {
		return <>{children}</>;
	}

	const returnUrl = `${pathname}${queryString ? `?${queryString}` : ""}`;

	router.push(`/login?returnUrl=${encodeURIComponent(returnUrl)}`);

	return null;
}

export default WithAuthentication;
