import React, { useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import RouterLink from "next/link";

import {
	Modal,
	useModalState,
	ModalInnerVariant,
	Link,
} from "@jasperlabs/jux-next";

import { queries, useCaptureGraphQLError } from "../../../../api";

import LoginForm from "../LoginForm";

import { useSession } from "../../../../contexts/session";
import { toast } from "../../../utilities/toast";

const ExpiredSession = () => {
	const { hasAuthenticatedSession, hasSessionExpired } = useSession();
	const captureLoginError = useCaptureGraphQLError();
	const modalState = useModalState();

	const { loading: isLoading, data = {} } = useQuery(queries.ME);
	const { me = null } = data;
	const { email } = me || {};

	const handleLoginAttemptFailed = useCallback(() => {
		toast.warning("The password provided is incorrect.");
	}, []);

	const handleGenericError = useCallback(
		(error) => {
			captureLoginError(error);
			toast.errorGeneric();
		},
		[captureLoginError],
	);

	useEffect(() => {
		const hasExpiredSession = hasAuthenticatedSession && hasSessionExpired;
		if (hasExpiredSession && !modalState.isOpen) {
			modalState.openModal();
		}
		if (!hasExpiredSession && modalState.isOpen) {
			modalState.closeModal();
		}
	}, [hasAuthenticatedSession, hasSessionExpired, modalState]);

	return (
		<Modal
			ariaLabel="Session expired modal"
			hasCloseButton={false}
			hideOnClickOutside={false}
			hideOnEsc={false}
			modalState={modalState}
			innerVariant={ModalInnerVariant.Card}
		>
			<div className="py-10 text-center">
				<h1 className="text-heading-sm mb-6 text-center">Session expired</h1>
				<p className="text-body-default text-color-body mb-6 text-center">
					Your session has expired. Please login again to continue.
				</p>
				{isLoading ? null : (
					<div className="mb-6">
						<LoginForm
							presetEmail={email}
							onLoginAttemptFailed={handleLoginAttemptFailed}
							onError={handleGenericError}
						/>
					</div>
				)}
				<Link
					component={RouterLink}
					href="/forgot-password"
					className="text-subheading-xs"
				>
					Forgot password?
				</Link>
			</div>
		</Modal>
	);
};

export default ExpiredSession;
